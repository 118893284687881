<div *ngIf="isLoading">Loading...</div>
<div *ngIf="!isLoading">
  <div class="container-fluid">
    <div class="row p-1">
      <div class="col-2 text-start">
        <a href="javascript:void(0)" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight">
          <img src="../../../assets/hamburger2.png" class="img-fluid my-auto p-2">
        </a>
      </div>
      <div class="col-6 my-auto text-center">
        <strong>{{ title }}</strong>
      </div>
      <div class="col-4 my-auto text-end">
        <img [src]="logoUrl" class="main-logo img-fluid" alt="Logo">
      </div>
    </div>
  </div>
  <app-offcanvas></app-offcanvas>
</div>