import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-my-entries',
  templateUrl: './my-entries.component.html',
  styleUrls: ['./my-entries.component.css']
})

export class MyEntriesComponent implements OnInit {
  public responseObject: any = {};
  public isResponseReceived: boolean;
  public hasError: boolean;
  public isPreviewAvailable = false;
  public entityId = localStorage.getItem('selected_entity');
  public nameField: string = '';

  constructor(
    private http: HttpClient,
    private router: Router,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.getCards();
    // Retrieve and apply stored primary color
    const storedPrimaryColor = localStorage.getItem('primaryColor');
    if (storedPrimaryColor) {
      console.log('Applying stored primary color:', storedPrimaryColor);
      document.documentElement.style.setProperty('--primary-color', storedPrimaryColor);
    } else {
      console.log('No stored primary color found');
    }
  }

  getCards(): void {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));

    this.spinner.show();
    this.http.get(environment.url + '/entity-data/e/' + this.entityId, { headers }).subscribe(
      (val: any) => {
        this.spinner.hide();
        this.responseObject = val;
        this.isResponseReceived = true;
        this.isPreviewAvailable = true;
        this.determineNameField();
      },
      (response) => {
        this.spinner.hide();
        this.hasError = true;
      }
    );
  }

  determineNameField(): void {
    if (this.responseObject.docs.length > 0) {
      // Assume that the 'name' field is the first field in the object
      this.nameField = Object.keys(this.responseObject.docs[0].data).find(key => key.includes('name')) || 'name';
    }
  }

  getFieldValue(data: any, key: string): string {
    return data[key] || 'N/A'; // Provide a default value if the key is not found
  }

  getKeys(data: any): string[] {
    return Object.keys(data).filter(key => !this.isNameField(key) && !this.isImageField(key));
  }

  isNameField(key: string): boolean {
    return key === this.nameField;
  }

  isImageField(key: string): boolean {
    return key.toLowerCase().includes('image');
  }

  emailCard(post_id: any): void {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));

    this.spinner.show();
    this.http.post(environment.url + '/email', { id: post_id, employee_id: localStorage.getItem('employeeID') }, { headers }).subscribe(
      (val: any) => {
        this.spinner.hide();
        if (val.success === 'true') {
          this.isResponseReceived = true;
          this.isPreviewAvailable = true;
          $(`#card_${post_id}`).addClass('d-block');
        }
      },
      (response) => {
        this.spinner.hide();
        this.hasError = true;
      }
    );
  }
}
