import { Component, OnInit } from '@angular/core';
import { AuthServiceService } from '../../auth-service.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AppComponent } from '../../app.component';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr'; // Import ToastrService

@Component({
  selector: 'app-select-template',
  templateUrl: './select-template.component.html',
  styleUrls: ['./select-template.component.css']
})
export class SelectTemplateComponent implements OnInit {
  public responseObject: any = { docs: [] };
  public requestObject: any = {};
  public isResponseReceived: boolean = false;
  public hasError: boolean = false;
  public campaignID: string = localStorage.getItem('campaignID');
  public orgaisationId: string = localStorage.getItem('org');

  constructor(
    private authService: AuthServiceService,
    private http: HttpClient,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private deviceService: DeviceDetectorService,
    private appComponent: AppComponent,
    private toastr: ToastrService // Inject ToastrService
  ) { }

  ngOnInit(): void {
    this.getPosters();
    // Retrieve and apply stored primary color
    const storedPrimaryColor = localStorage.getItem('primaryColor');
    if (storedPrimaryColor) {
      console.log('Applying stored primary color:', storedPrimaryColor);
      document.documentElement.style.setProperty('--primary-color', storedPrimaryColor);
    } else {
      console.log('No stored primary color found');
    }
  }

  nextStep(poster: any) {
    localStorage.setItem('poster_id', poster.id);
    localStorage.setItem('poster_url', poster.sourceImage);
    localStorage.setItem('has_custom_message', poster.has_custom_message);
    localStorage.setItem('selected_entity', poster.entityConfiguration.entityId);
    localStorage.setItem('selected_entity_name', poster.entityConfiguration.entityName);
    this.router.navigateByUrl('/entity/' + poster.entityConfiguration.entityId);
    localStorage.setItem('entity_mode', "listing");
  }

  getPosters() {
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      const campaignID = parseInt(params.get('id'), 0);
      localStorage.setItem('campaign_id', campaignID.toString());

      const headers = new HttpHeaders()
        .set('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));

      this.spinner.show();
      this.http.get(environment.url + '/template/campaign/' + this.campaignID, { headers }).subscribe(
        (val) => {
          this.spinner.hide();
          this.responseObject = val;
          this.isResponseReceived = true;
          if (!Array.isArray(this.responseObject.docs)) {
            this.responseObject.docs = [];
          }
          if (this.responseObject.docs.length === 0) {
            // No templates found, show error message
            this.toastr.error('No templates found for this campaign.', 'Error');
          }
        },
        (response) => {
          this.spinner.hide();
          this.hasError = true;
          this.toastr.error('An error occurred while fetching templates.', 'Error');
        }
      );
    });
  }
}
