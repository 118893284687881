import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BrandingService {
  private apiUrl = environment.url + 'orgsettings/branding';

  constructor(private http: HttpClient) { }

  getBrandingData(url: string): Observable<any> {
    // Create a new URL object from the given URL
    const parsedUrl = new URL(url);

    // Extract the hostname (i.e., the domain name without path or query parameters)
    const strippedUrl = parsedUrl.hostname;

    // Construct the request URL
    const requestUrl = `${this.apiUrl}?url=${strippedUrl}`;

    return this.http.get(requestUrl).pipe(
      map((data: any) => data),
      catchError(this.handleError('getBrandingData', []))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }
}
