import { style } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment'; // Add this line
import { HttpClient } from '@angular/common/http'; // Add this line
import { NgxSpinnerService } from 'ngx-spinner'; // Add this line
import { Router } from '@angular/router'; // Add this line
import { BrandingService } from '../../shared/branding/branding.service'; // Add this line

@Component({
  selector: 'app-offcanvas',
  templateUrl: './offcanvas.component.html',
  styleUrls: ['./offcanvas.component.css']
})
export class OffcanvasComponent implements OnInit {

  public entity: string;
  public entitySelected: boolean = false;
  public orgaisationId: any = localStorage.getItem('org');
  public hasError: boolean = false;
  public responseObject: any = {};
  public entityData: any = {};
  private entityId: string;
  public userId: string = localStorage.getItem('user_id');
  logoUrl: string = ''; // Initialize as empty string
  defaultLogoUrl = '../../../assets/uns/logo.png'; // Path to your default logo
  isLoading = true;


  constructor(private spinner: NgxSpinnerService, private http: HttpClient, private router: Router, private brandingService: BrandingService) { }

  ngOnInit(): void {
    this.getEntity();
    this.fetchBrandingData();
    // Retrieve and apply stored primary color
    const storedPrimaryColor = localStorage.getItem('primaryColor');
    if (storedPrimaryColor) {
      console.log('Applying stored primary color:', storedPrimaryColor);
      document.documentElement.style.setProperty('--primary-color', storedPrimaryColor);
    } else {
      console.log('No stored primary color found');
    }
  }

  private fetchBrandingData(): void {
    const fullUrl = window.location.origin + this.router.url; // or use another URL as needed
    console.log('Full URL:', fullUrl);
    this.brandingService.getBrandingData(fullUrl).subscribe(data => {
      console.log('Branding data:', data);
      if (data && data.docs && data.docs.length > 0) {
        const branding = data.docs[0];
        this.logoUrl = branding.logo || this.defaultLogoUrl;
      } else {
        this.logoUrl = this.defaultLogoUrl;
      }
      this.isLoading = false;
    }, error => {
      console.error('Error fetching branding data:', error);
      this.logoUrl = this.defaultLogoUrl;
      this.isLoading = false;
    });
  }

  getEntity() {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));

    this.http.get(environment.url + 'entity/', { headers }).subscribe(
      (val: any) => {
        // console.log('Data', val.docs);
        this.responseObject = val;
        this.spinner.hide();
      },
      (response) => {
        this.spinner.hide();
        this.hasError = true;
      }
    );
  }

  storeEntityId(id: string, mode: string = 'full') {
    localStorage.setItem('selected_entity', id);
    localStorage.setItem('entity_mode', mode);

    // Navigate to the route and force a reload
    this.router.navigate([`/entity/${id}`]).then(() => {
      // window.location.reload(); // Force a reload
    });
  }

  ngOnDestroy() {
    document.body.style.overflow = 'auto';
  }
}
