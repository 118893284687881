<div class="container p-2">
  <div class="red-box">
    <app-header title="Select Template"></app-header>

    <div class="row">
      <ng-container *ngIf="isResponseReceived">
        <ng-container *ngIf="responseObject.docs.length > 0; else noTemplates">
          <div class="col-12 col-md-3 mt-2 p-md-3 text-center" *ngFor="let poster of responseObject.docs">
            <div class="card shadow mt-2" (click)="nextStep(poster)">
              <div class="card-header">
                {{ poster.name }}
              </div>

              <div class="card-body">
                <img src="{{ poster.sourceImage }}" class="img-fluid" />
              </div>
            </div>
          </div>
        </ng-container>
        <ng-template #noTemplates>
          <div class="col-12 text-center mt-5">
            <h2 class="text-danger"><b>No templates found</b></h2>
          </div>
        </ng-template>
      </ng-container>
    </div>
  </div>
</div>