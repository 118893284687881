<div class="container p-2">
  <div class="red-box">
    <app-header title="My Entries"></app-header>

    <div class="row p-2">
      <div *ngFor="let entity of responseObject.docs">
        <div class="col-12 col-md-4 mt-3 mb-3" *ngIf="isResponseReceived">
          <div class="card">
            <div class="card-header small">
              Name: {{ getFieldValue(entity.data, nameField) }}<br>
            </div>

            <div class="card-body small">
              <div *ngFor="let key of getKeys(entity.data)">
                <p *ngIf="entity.data[key]">{{ key | removeUnderscore }}: {{ entity.data[key] }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 text-center" *ngIf="!isResponseReceived">
        <h2 class="text-danger">No Records Found</h2>
      </div>
    </div>
  </div>
</div>