<div class="container p-2">
  <div class="red-box">
    <app-header title="Select Campaign"></app-header>

    <div class="row">
      <div class="col-12 col-md-4 offset-md-4 mt-4 p-md-0 text-center">
        <mat-list *ngIf="isResponseReceived">
          <ng-container *ngFor="let campaign of responseObject.docs">
            <mat-list-item (click)="openTemplates(campaign)">{{ campaign.name }}</mat-list-item>
            <mat-divider></mat-divider>
          </ng-container>
        </mat-list>
      </div>
    </div>

  </div>
</div>
