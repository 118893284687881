import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatPaginatorModule } from '@angular/material/paginator';
// import { PaginatorComponent } from './paginator.component'; // Import your component
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [
        // PaginatorComponent // Declare your component here
    ],
    imports: [
        CommonModule,
        MatPaginatorModule,
        FormsModule
    ],
    exports: [
        // PaginatorComponent // Export your component if needed
    ]
})
export class PaginatorModule { }