import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { EntityDataComponent } from './entity/entity-data/entity-data.component';
import { MyEntriesComponent } from './pages/my-enteries/my-entries.component';
import { SelectCampaignComponent } from './pages/select-campaign/select-campaign.component';
import { SelectTemplateComponent } from './pages/select-template/select-template.component';
import { EntityListingComponent } from './entity/entity-listing/entity-listing.component';
import { GeneratePosterComponent } from './pages/generate-poster/generate-poster.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth/login',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'pages',
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
  },
  {
    path: 'entity',
    loadChildren: () => import('./entity/entity.module').then(m => m.EntityModule)
  }

  // {
  //   path: 'select-campaign',
  //   component: SelectCampaignComponent
  // },
  // {
  //   path: 'select-template',
  //   component: SelectTemplateComponent,
  // },
  // {
  //   path: 'entity/:entityID',
  //   component: EntityListingComponent,
  // },
  // {
  //   path: 'generate/:templateID/:entityID',
  //   component: GeneratePosterComponent
  // },
  // {
  //   path: 'my-post-cards',
  //   component: MyEntriesComponent,
  // },
  // {
  //   path: 'entity/:entityID/create',
  //   component: EntityDataComponent,
  // },
  // {
  //   path: 'entity/:entityID/edit/:id',
  //   component: EntityDataComponent,
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }), CommonModule],
  exports: [RouterModule],
})
export class AppRoutingModule { }
