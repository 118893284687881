<div class="container p-2">
  <div class="red-box">
    <app-header [title]="entityName"></app-header>

    <div class="row">
      <div class="col-12 col-md-6 offset-md-3 mt-2 p-md-0 text-center">
        <div *ngIf="isResponseReceived">
          <div class="input-group mb-3">
            <input type="text" class="form-control" [(ngModel)]="filterKeyword"
              placeholder="Search {{entityName}}.. " />
          </div>

          <table class="table table-striped table-bordered">
            <ng-container *ngIf="responseObject.totalDocs > 0">
              <tr *ngFor="let doctor of responseObject.docs | filter : filterKeyword; let i = index">
                <td>{{ i + 1 }}</td>
                <td (click)="isListingMode ? nextStep(doctor) : null">
                  <ng-container *ngIf="nameField">
                    {{ doctor.data[nameField] }}
                  </ng-container>
                  <br />
                </td>
                <td *ngIf="isListingMode">
                  <a class="font-weight-bold" type="button" (click)="nextStep(doctor)" title="Generate Report">
                    <i class="glyphicon glyphicon-circle-arrow-right"></i>
                  </a>
                </td>
                <!-- Conditionally display edit and delete options -->
                <td *ngIf="!isListingMode">
                  <a class="" type="button" (click)="editDoctor(doctor)" title="Edit">
                    <i class="glyphicon glyphicon-edit"></i>
                  </a>
                </td>
                <td *ngIf="!isListingMode">
                  <a type="button" (click)="deleteDoctor(doctor)" title="Delete Doctor">
                    <i class="glyphicon glyphicon-remove"></i>
                  </a>
                </td>
              </tr>
            </ng-container>
          </table>


          <ng-container *ngIf="responseObject.totalDocs === 0">
            <small class="text-dark">No {{ entityName }} found.</small>
          </ng-container>

          <div class="mt-4">
            <button class="btn btn-primary" (click)="addNewDoctor()">
              Add {{ entityName }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>