<form #form="ngForm">
  <!-- Render all fields except image fields -->
  <div *ngFor="let field of nonImageFields">
    <label [for]="field.title">{{ field.title }}:</label>
    <ng-container [ngSwitch]="field.type">
      <!-- Text -->
      <input *ngSwitchCase="'text'" class="form-control mb-2" [id]="field.title" [name]="field.identifier"
        [placeholder]="field.placeholder" type="text" [(ngModel)]="formInfo[field.identifier]" />

      <!-- Email -->
      <input *ngSwitchCase="'email'" class="form-control mb-2" [id]="field.title" [name]="field.identifier"
        [placeholder]="field.placeholder" type="email" [(ngModel)]="formInfo[field.identifier]" />

      <!-- Mobile Number -->
      <input *ngSwitchCase="'number'" class="form-control mb-2" [id]="field.title" [name]="field.identifier"
        [placeholder]="field.placeholder" type="number" [(ngModel)]="formInfo[field.identifier]" />

      <!-- Date -->
      <input *ngSwitchCase="'date'" class="form-control mb-2" [id]="field.title" [name]="field.identifier"
        [placeholder]="field.placeholder" type="date" [(ngModel)]="formInfo[field.identifier]" />

      <!-- Time -->
      <input *ngSwitchCase="'time'" class="form-control mb-2" [id]="field.title" [name]="field.identifier"
        [placeholder]="field.placeholder" type="time" [(ngModel)]="formInfo[field.identifier]" />

      <!-- Dropdown -->
      <select *ngSwitchCase="'dropdown'" class="form-control form-select mb-2" [id]="field.title"
        [name]="field.identifier" [(ngModel)]="formInfo[field.identifier]">
        <option value="" disabled selected>Select</option>
        <option *ngFor="let option of field.options" [value]="option">{{ option }}</option>
      </select>

      <!-- Checkbox -->
      <input *ngSwitchCase="'checkbox'" class="form-check-input" [id]="field.title" [name]="field.identifier"
        type="checkbox" [(ngModel)]="formInfo[field.identifier]" />
    </ng-container>
  </div>

  <!-- Render image field always at the end -->
  <div *ngIf="imageFields.length > 0">
    <div *ngFor="let field of imageFields">
      <label [for]="field.title">{{ field.title }}:</label>
      <input class="form-control mb-2" [id]="field.title" [name]="field.identifier" type="file"
        (change)="fileChangeEvent($event); setSelectedImageField(field.identifier)" accept="image/*" />
      <!-- Show prefilled image only if editing and image is available -->
      <div *ngIf="showPrefilledImage && formInfo[field.identifier] && !imageChangedEvent">
        <img [src]="formInfo[field.identifier]" alt="Prefilled Image" class="img-thumbnail" />
      </div>
    </div>
  </div>

  <!-- Image cropper -->
  <image-cropper *ngIf="imageChangedEvent" [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
    [aspectRatio]="1 / 1" format="jpeg" (imageCropped)="imageCropped($event)" (cropperReady)="cropperReady()"
    (loadImageFailed)="loadImageFailed()"></image-cropper>

  <div class="text-center">
    <button class="btn btn-primary btn-block text-yellow mt-3" (click)="saveButton()">
      {{ isEditMode ? 'Update' : 'Save' }}
    </button>
  </div>
</form>