// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // url: 'http://localhost/upthink/35-Glenmark-Customisation/05-Code/wp/wp-json/jwt-auth/v1',
  // url: 'https://uns-api.upconnect.co.in/wp-json/jwt-auth/v1'
  // url: 'https://primepixels.net/v1'
  // url: 'http://localhost:3000/v1/'
  url: 'https://api.primepixels.net/v1/'
};
// export const baseUrl = 'https://sompraz.pharmaservices.in/wp-json/jwt-auth/v1/';
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
