import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthServiceService } from '../../auth-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  oldPassword: string = '';
  newPassword: string = '';
  showOldPassword: boolean = false;
  showNewPassword: boolean = false;

  constructor(
    private http: HttpClient,
    private router: Router,
    private authService: AuthServiceService,
    private toastr: ToastrService // Inject ToastrService
  ) { }

  ngOnInit(): void {
  }

  toggleOldPasswordVisibility(): void {
    this.showOldPassword = !this.showOldPassword;
  }

  toggleNewPasswordVisibility(): void {
    this.showNewPassword = !this.showNewPassword;
  }

  validatePasswords(): boolean {
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;

    if (!passwordRegex.test(this.newPassword)) {
      this.toastr.warning('New password must be at least 8 characters long and include both letters and numbers.', 'Validation Error');
      return false;
    }

    return true;
  }

  onSubmit(): void {
    if (!this.validatePasswords()) {
      return; // Stop submission if validation fails
    }

    const accessToken = localStorage.getItem('accessToken');

    if (!accessToken) {
      console.error('No access token found in localStorage');
      this.toastr.error('Authentication error. Please log in again.', 'Error');
      return;
    }

    const headers = new HttpHeaders()
      .set('Authorization', `Bearer ${this.authService.getToken()}`);

    const body = {
      oldPassword: this.oldPassword,
      newPassword: this.newPassword
    };

    this.http.post(environment.url + 'auth/change-password', body, { headers }).subscribe(
      (response) => {
        console.log('Password changed successfully!', response);
        this.toastr.success('Password changed successfully!', 'Success');
        this.router.navigate(['/']);
      },
      error => {
        console.error('Error changing password', error);

        // Check for specific error message related to old password
        if (error.error && error.error.message) {
          if (error.error.message.includes('old password incorrect')) {
            this.toastr.error('The old password you entered is incorrect. Please try again.', 'Error');
          } else {
            this.toastr.error(error.error.message, 'Error');
          }
        } else {
          this.toastr.error('An unexpected error occurred. Please try again later.', 'Error');
        }
      }
    );
  }
}
