import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeUnderscore'
})
export class RemoveUnderscorePipe implements PipeTransform {

  transform(value: string): string {
    if (!value) return value;

    // Replace all underscores with spaces
    let result = value.replace(/_/g, ' ');

    // Capitalize the first letter of each word
    result = result.replace(/\b\w/g, char => char.toUpperCase());

    return result;
  }
}
