<app-offcanvas></app-offcanvas>

<div class="container p-2">
  <div class="red-box">
    <app-header [title]="title"></app-header> <!-- Bind the title here -->
    <div class="row mt-2">
      <div class="col-12 col-md-8 offset-md-2">
        <div class="card mt-2 border-0">
          <div class="card-body">
            <app-dynamic-form></app-dynamic-form>
            <div class="alert alert-danger mt-3" *ngIf="hasError">
              <p>All fields are required.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>