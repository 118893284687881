import { Component, Input, OnInit } from '@angular/core';
import { BrandingService } from '../../shared/branding/branding.service'; // Adjust the import path as necessary
import { Router } from '@angular/router'; // Import the Router module

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  @Input() title: string = 'Default Title';
  logoUrl: string = ''; // Initialize as empty string
  defaultLogoUrl = '../../../assets/uns/logo.png'; // Path to your default logo
  isLoading = true;

  constructor(private brandingService: BrandingService, private router: Router) { }

  ngOnInit(): void {
    this.fetchBrandingData();
    // Retrieve and apply stored primary color
    const storedPrimaryColor = localStorage.getItem('primaryColor');
    if (storedPrimaryColor) {
      console.log('Applying stored primary color:', storedPrimaryColor);
      document.documentElement.style.setProperty('--primary-color', storedPrimaryColor);
    } else {
      console.log('No stored primary color found');
    }
  }

  private fetchBrandingData(): void {
    const fullUrl = window.location.origin + this.router.url; // or use another URL as needed
    console.log('Full URL:', fullUrl);
    this.brandingService.getBrandingData(fullUrl).subscribe(data => {
      console.log('Branding data:', data);
      if (data && data.docs && data.docs.length > 0) {
        const branding = data.docs[0];
        this.logoUrl = branding.logo || this.defaultLogoUrl;
      } else {
        this.logoUrl = this.defaultLogoUrl;
      }
      this.isLoading = false;
    }, error => {
      console.error('Error fetching branding data:', error);
      this.logoUrl = this.defaultLogoUrl;
      this.isLoading = false;
    });
  }
}
