import { Component, OnInit } from '@angular/core';
import { AuthServiceService } from '../../auth-service.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { LoginResponse } from '../../shared/_models/user';
import { NgxSpinnerService } from 'ngx-spinner';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AppComponent } from '../../app.component';
import { Title } from '@angular/platform-browser';
import { BrandingService } from '../../shared/branding/branding.service'; // Import the new service
import { environment } from 'src/environments/environment'; // Import the environment variable

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  model: any = {};
  deviceInfo = null;
  public isMobile;
  public isDesktop;
  public isTablet;
  public responseObject: any = {};
  public requestObject: any = { identifier: '' }; // Initialize identifier
  public isResponseReceived: boolean;
  public hasError: boolean;
  resp: LoginResponse;
  fullUrl: string;
  brandingData: any;

  public topBannerUrl: string | null = null;
  public bottomBannerUrl: string | null = null;
  public loading: boolean = true;
  public logoUrl: string | null = null;
  brandingDataReceived: boolean = false;

  constructor(
    private authService: AuthServiceService,
    private http: HttpClient,
    private router: Router,
    private spinner: NgxSpinnerService,
    private deviceService: DeviceDetectorService,
    private appComponent: AppComponent,
    private titleService: Title,
    private brandingService: BrandingService  // Inject the new service
  ) {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktop = this.deviceService.isDesktop();
  }

  ngOnInit(): void {
    this.setUrlAndFetchData();

    // Retrieve and apply stored primary color
    const storedPrimaryColor = localStorage.getItem('primaryColor');
    if (storedPrimaryColor) {
      console.log('Applying stored primary color:', storedPrimaryColor);
      document.documentElement.style.setProperty('--primary-color', storedPrimaryColor);
    } else {
      console.log('No stored primary color found');
    }

    if (localStorage.getItem('accessToken')) {
      this.router.navigate(['/select-campaign']);
    } else {
      this.appComponent.isLoggedIn = false;
    }
  }

  private setUrlAndFetchData(): void {
    this.fullUrl = window.location.origin + this.router.url;
    console.log('Full URL:', this.fullUrl);

    this.loading = true;
    this.brandingService.getBrandingData(this.fullUrl).subscribe((data) => {
      console.log('Response Data:', data);
      this.brandingData = data;
      this.applyConfig();
      this.loading = false;
    }, (error) => {
      console.error('Error fetching branding data:', error);
      this.loading = false;
    });
  }

  applyConfig() {
    if (this.brandingData && this.brandingData.docs && this.brandingData.docs.length > 0) {
      this.brandingDataReceived = true;
      const branding = this.brandingData.docs[0];
      const primaryColor = branding.primaryColor || '#8bc541'; // Default to a fallback color if not provided

      console.log('Applying new primary color:', primaryColor);
      document.documentElement.style.setProperty('--primary-color', primaryColor);
      localStorage.setItem('primaryColor', primaryColor); // Save to localStorage

      this.topBannerUrl = branding.topBanner || null;
      this.bottomBannerUrl = branding.bottomBanner || null;
      this.logoUrl = branding.logo || null;

      const title = branding.organisationId.organisationName || 'Default Title';
      this.titleService.setTitle(title);
    } else {
      console.error('Branding data is not available or is empty.');
    }
  }

  login() {
    this.spinner.show();

    // Determine if the identifier is an email or employee ID
    let loginPayload: any = { password: this.requestObject.password };

    if (this.isEmail(this.requestObject.identifier)) {
      loginPayload.email = this.requestObject.identifier;
    } else {
      loginPayload.employeeID = this.requestObject.identifier;
    }

    // Send the login request with appropriate payload
    this.http.post(environment.url + '/auth/login', loginPayload).subscribe(
      (val) => {
        this.spinner.hide();
        this.responseObject = val;
        localStorage.setItem('username', this.responseObject.email);
        localStorage.setItem('accessToken', this.responseObject.tokens.access.token);
        localStorage.setItem('first_name', this.responseObject.first_name);
        localStorage.setItem('employeeID', this.responseObject.user.email); // Adjust as needed
        localStorage.setItem('org', this.responseObject.user.organisationId);
        localStorage.setItem('user_id', this.responseObject.user.id);
        this.isResponseReceived = true;
        this.router.navigate(['/select-campaign']);
      },
      (response) => {
        this.spinner.hide();
        this.hasError = true;
      }
    );
  }

  private isEmail(identifier: string): boolean {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(identifier);
  }

  forgotPassword() {
    this.router.navigate(['/forgot-password']);
    alert('Email containing reset password link has been sent to your email address');
  }
}
