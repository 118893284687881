import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-entity-listing',
  templateUrl: './entity-listing.component.html',
  styleUrls: ['./entity-listing.component.css']
})
export class EntityListingComponent implements OnInit {
  public responseObject: any = {};
  public isResponseReceived: boolean = false;
  public hasError: boolean = false;
  public filterKeyword: string = '';
  public orgaisationId: string = localStorage.getItem('org');
  public entityName: string = '';
  public entityID: string = localStorage.getItem('selected_entity');
  public isListingMode: boolean = false;
  public tempalteID: string = '';
  public nameField: string | null = null;

  constructor(
    private http: HttpClient,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit(): void {
    this.isListingMode = localStorage.getItem('entity_mode') === 'listing';
    this.tempalteID = localStorage.getItem('poster_id');
    this.getDoctors();
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));
    this.http.get<any>(
      `${environment.url}entity/${this.entityID}`,
      { headers }
    ).subscribe(
      (val: any) => { this.entityName = val.name; },
      (error) => { console.error('Error fetching entity:', error); }
    );

    // Retrieve and apply stored primary color
    const storedPrimaryColor = localStorage.getItem('primaryColor');
    if (storedPrimaryColor) {
      console.log('Applying stored primary color:', storedPrimaryColor);
      document.documentElement.style.setProperty('--primary-color', storedPrimaryColor);
    } else {
      console.log('No stored primary color found');
    }
  }

  getDoctors() {
    this.spinner.show();
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));
    this.http.get<any>(`${environment.url}entity-data/e/${this.entityID}`, { headers }).subscribe(
      (val) => {
        this.spinner.hide();
        this.responseObject = val;
        this.isResponseReceived = true;
        if (this.responseObject.docs && this.responseObject.docs.length > 0) {
          const firstDocData = this.responseObject.docs[0].data;
          this.nameField = Object.keys(firstDocData).find(key => key.toLowerCase().includes('name')) || null;
        }
      },
      (response) => {
        this.spinner.hide();
        this.hasError = true;
      }
    );
  }

  deleteDoctor(doctor: any): void {
    if (this.nameField && confirm(`Are you sure to delete "${doctor.data[this.nameField]}"?`)) {
      this.spinner.show();
      const headers = new HttpHeaders()
        .set('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));
      this.http.delete(`${environment.url}entity-data/${doctor.id}`, { headers }).subscribe(
        (val) => {
          this.spinner.hide();
          this.getDoctors();
        },
        (response) => {
          this.spinner.hide();
          this.hasError = true;
        }
      );
    }
  }

  editDoctor(doctor: any) {
    localStorage.setItem('doctor_id', doctor.id);
    this.router.navigateByUrl(`/entity/${this.entityID}/edit/${doctor.id}`);
  }

  addNewDoctor() {
    localStorage.removeItem('doctor_id'); // Clear doctorID
    this.router.navigateByUrl(`/entity/${this.entityID}/create`);
  }

  nextStep(entityData: any) {
    if (this.nameField) {
      const imageField = Object.keys(entityData.data).find(key => key.toLowerCase().includes('image'));
      const doctorImage = entityData.data[imageField] || '';
      localStorage.setItem('doctor_id', entityData.id.toString());
      localStorage.setItem('doctor_image', doctorImage);
      localStorage.setItem('doctor_name', entityData.data[this.nameField] || '');
      this.router.navigateByUrl(`/generate/${this.tempalteID}/${entityData.id}`);
    }
  }
}
