<div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
    <div class="offcanvas-header pe-4">
        <img [src]="logoUrl" class="img-fluid w-25">
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">
        <div class="d-flex flex-column flex-shrink-0 text-dark bg-white">
            <!-- <p>Welcome, {{ userName }}</p> -->
            <hr>
            <ul class="nav nav-pills flex-column mb-auto">
                <li class="nav-item">
                    <a [routerLink]="['/']" href="javascript:void(0)" class="nav-link text-dark" aria-current="page">
                        <i class="fa fa-home" aria-hidden="true"></i> &nbsp; Home
                    </a>
                </li>
                <li class="nav-item" *ngFor="let item of responseObject.docs">
                    <a [routerLink]="['/entity', item.id]" class="nav-link text-dark"
                        (click)="storeEntityId(item.id, 'full')">
                        <i class="fa fa-home" aria-hidden="true"></i> &nbsp; Manage {{ item.name }}
                    </a>
                </li>

                <li class="nav-item">
                    <a [routerLink]="['/my-entries']" href="javascript:void(0)" class="nav-link text-dark"
                        aria-current="page">
                        <i class="fa fa-home" aria-hidden="true"></i> &nbsp; My Entries
                    </a>
                </li>

                <li class="nav-item">
                    <a [routerLink]="['/profile/', userId]" href="javascript:void(0)" class="nav-link text-dark"
                        aria-current="page">
                        <i class="fa fa-home" aria-hidden="true"></i> &nbsp; Profile
                    </a>
                </li>

                <li class="nav-item">
                    <a [routerLink]="['/change-password/', userId]" href="javascript:void(0)" class="nav-link text-dark"
                        aria-current="page">
                        <i class="fa fa-home" aria-hidden="true"></i> &nbsp; Change Password
                    </a>
                </li>

                <li>
                    <a [routerLink]="['/logout']" href="javascript:void(0)" class="nav-link text-dark">
                        <i class="fa fa-sign-out" aria-hidden="true"></i> &nbsp; Logout
                    </a>
                </li>
            </ul>
            <hr>
        </div>
    </div>
</div>