import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Teachers Day';
  public isLoggedIn = false;

  constructor(private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle('My New Dynamic Title');
    if (localStorage.getItem('employeeID')) {
      this.isLoggedIn = true;
    }
  }
}

