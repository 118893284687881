<div class="container p-2">
    <div class="red-box">
        <app-header title="Profile"></app-header>

        <div class="row p-2">
            <div class="col-12 col-md-4 mt-3 mb-3">
                <!-- Profile Information -->
                <div class="profile-info">
                    <h2>User Profile</h2>

                    <!-- Personal Information -->
                    <div class="profile-section">
                        <h4>Personal Information</h4>
                        <div class="profile-detail">
                            <strong>Name:</strong> <span>{{ (user.firstName || '') + ' ' + (user.lastName || '')
                                }}</span>
                        </div>
                        <div class="profile-detail">
                            <strong>Role:</strong> <span>{{ user.role || 'N/A' }}</span>
                        </div>
                        <div class="profile-detail">
                            <strong>Email Verified:</strong> <span>{{ user.isEmailVerified ? 'Yes' : 'No' }}</span>
                        </div>
                    </div>

                    <!-- Contact Details -->
                    <div class="profile-section">
                        <h4>Contact Details</h4>
                        <div class="profile-detail">
                            <strong>Email:</strong> <span>{{ user.email || 'N/A' }}</span>
                        </div>
                        <div class="profile-detail">
                            <strong>Mobile:</strong> <span>{{ user.phoneNumber || 'N/A' }}</span>
                        </div>
                        <div class="profile-detail">
                            <strong>City:</strong> <span>{{ user.city || 'N/A' }}</span>
                        </div>
                        <div class="profile-detail">
                            <strong>State:</strong> <span>{{ user.state || 'N/A' }}</span>
                        </div>
                        <div class="profile-detail">
                            <strong>Country:</strong> <span>{{ user.country || 'N/A' }}</span>
                        </div>
                    </div>

                    <!-- Job Details -->
                    <div class="profile-section">
                        <h4>Job Details</h4>
                        <div class="profile-detail">
                            <strong>Division:</strong> <span>{{ user.division || 'N/A' }}</span>
                        </div>
                        <div class="profile-detail">
                            <strong>Designation:</strong> <span>{{ user.designation || 'N/A' }}</span>
                        </div>
                        <div class="profile-detail">
                            <strong>HQ:</strong> <span>{{ user.hq || 'N/A' }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>