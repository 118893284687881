import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import * as $ from 'jquery';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { DomSanitizer } from '@angular/platform-browser';
import { AppComponent } from '../../app.component';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';


@Component({
  selector: 'app-entity-data',
  templateUrl: './entity-data.component.html',
  styleUrls: ['./entity-data.component.css']
})

export class EntityDataComponent implements OnInit {

  public doctorInfo: any;
  public doctorName: string;
  public firstName: string;
  public lastName: string;
  public doctorSpeciality = '0';
  public doctorInitial = '';
  public doctorEmail: string;
  public doctorMobile: string;
  public doctorDateOfBirth: string;
  public hospitalArea: string;
  public unnatiID: string;

  public fileName = '';
  public formData: FormData;
  public selectedFile: File;
  imageChangedEvent: any = '';
  croppedImage: any = '';

  public responseObject: any = {};
  public requestObject: any = {};
  public isResponseReceived: boolean;
  public hasError: boolean;
  public doctorID;
  public doctorImage;
  public orgaisationId: any = localStorage.getItem('org');
  public entityId: any = localStorage.getItem('selected_entity');
  public totalDocs: number;
  public entity: any = {};
  public fields: string;
  public entityName = localStorage.getItem('selected_entity_name');
  public title: string;
  constructor(
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private router: Router,
    private spinner: NgxSpinnerService,
    private appComponent: AppComponent,
  ) {

  }


  ngOnInit(): void {
    this.appComponent.isLoggedIn = true;

    this.activatedRoute.paramMap.subscribe(params => {
      this.doctorID = parseInt(params.get('id'), 0);
      this.title = this.doctorID ? 'Edit' : 'Create'; // Set title based on id
      // if (this.doctorID) {
      //   this.getDoctor();
      // }
    });

    // Retrieve and apply stored primary color
    const storedPrimaryColor = localStorage.getItem('primaryColor');
    if (storedPrimaryColor) {
      console.log('Applying stored primary color:', storedPrimaryColor);
      document.documentElement.style.setProperty('--primary-color', storedPrimaryColor);
    } else {
      console.log('No stored primary color found');
    }
  }

  onFileSelected(event) {
    this.selectedFile = event.target.files[0];
    if (this.selectedFile) {
      this.fileName = this.selectedFile.name;
      this.formData = new FormData();
      this.formData.append('file', this.selectedFile);
    }
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    console.log(this.croppedImage);
  }

  imageLoaded(image: LoadedImage) {
    // show cropper
  }

  // getDoctor() {
  //   this.spinner.show();
  //   const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));
  //   this.http.get(environment.url + 'doctors/org/' + this.doctorID, { headers }).subscribe(
  //     (val: any) => {
  //       this.spinner.hide();
  //       this.doctorName = val.docs.firstName;
  //       this.doctorImage = val.docs.doctorPhoto;
  //     },
  //     () => {
  //       this.spinner.hide();
  //       this.hasError = true;
  //     }
  //   );
  // }


  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }

  nextStep(): void {

    if (this.doctorID) {
      if (this.doctorName === null) {
        this.hasError = true;
        return;
      }
    }
    //   else {
    //     if (this.doctorName === null || !this.croppedImage) {
    //       this.hasError = true;
    //       return;
    //     }
    // }

    const newFormData: FormData = new FormData();
    // newFormData.append('doctor_speciality', this.doctorSpeciality);
    newFormData.append('firstName', this.doctorName);
    // newFormData.append('doctor_initial', this.doctorInitial);
    // newFormData.append('hospital_area', this.hospitalArea);
    newFormData.append('doctorPhoto', this.croppedImage);
    // newFormData.append('unnati_id', this.unnatiID);

    // if (this.doctorID) {
    //   newFormData.append('doctor_id', this.doctorID);
    // }

    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));

    this.spinner.show();
    this.http.post(environment.url + 'doctors', newFormData, { headers }).subscribe(
      (val: any) => {
        this.spinner.hide();
        if (val.success === true) {
          console.log('success');
          localStorage.setItem('postID', val.id);
          this.isResponseReceived = true;
          this.router.navigate(['entiy/', this.entityId]);
        }
      },
      (response) => {
        this.spinner.hide();
        this.hasError = true;
        this.router.navigate(['logout']);
      }
    );
  }






  // fetchFormFields() {
  //   console.log('fetchFormFields called');
  //   const headers = new HttpHeaders()
  //     .set('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));

  //   this.http.get(environment.url + 'entity/' + this.entityId, { headers }).subscribe((val: any) => {
  //     this.entityName = val.name;
  //     console.log(this.entityName);
  //   });
  // }

  // loadFormConfiguration() {
  //   // Retrieve the ID from local storage
  //   const id = localStorage.getItem('formId');

  //   if (id) {
  //     // Fetch form configuration from API based on the ID
  //     this.http.get<any[]>(`https://api.example.com/forms/${id}`).subscribe(data => {
  //       this.fields = data;

  //       // Initialize form data
  //       this.fields.forEach(field => {
  //         this.formData[field.name] = field.value || ''; // Initialize with default values if available
  //       });
  //     });
  //   }
  // }
}
