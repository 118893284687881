import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from './../environments/environment';
import { Router } from '@angular/router';
import { LoginResponse } from './shared/_models/user';

@Injectable({
  providedIn: 'root',
})
export class AuthServiceService {
  constructor(private http: HttpClient, private router: Router) {
  }

  // Checking if token is set
  isLoggedIn() {
    return localStorage.getItem('token') != null;
  }

  // After clearing localStorage redirect to login screen
  logout() {
    localStorage.clear();
    this.router.navigate(['/']);
  }

  getToken() {
    return localStorage.getItem('accessToken');
  }

  // Get data from server for Dashboard
  getData(data): Observable<LoginResponse> {
    console.log('/////');
    console.log(data);
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.post<LoginResponse>(
      environment.url + '/case-studies',
      data,
      { headers }
    );
  }

  getBonusQuestion(data): Observable<LoginResponse> {
    console.log('/////');
    console.log(data);
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get<LoginResponse>(
      environment.url + '/bonus',
      { headers }
    );
  }

  getFacts(data): Observable<any> {
    console.log('/////');
    console.log(data);
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get<any>(
      environment.url + '/facts',
      { headers }
    );
  }

  getScore(): Observable<any> {
    console.log('/////uu');
    console.log();
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get<any>(
      environment.url + '/score',
      { headers }
    );
  }
}
