<div class="container p-2">
    <div class="red-box">
        <app-header title="Change Password"></app-header>
        <div class="row justify-content-center">
            <div class="col-md-8 col-lg-6">
                <div class="card mt-4">
                    <div class="card-body">
                        <form (ngSubmit)="onSubmit()" #changePasswordForm="ngForm">
                            <div class="mb-3">
                                <label for="oldPassword" class="form-label">Old Password</label>
                                <div class="input-group">
                                    <input type="{{ showOldPassword ? 'text' : 'password' }}" id="oldPassword"
                                        name="oldPassword" class="form-control" [(ngModel)]="oldPassword" required />
                                    <button type="button" class="btn btn-outline-secondary"
                                        (click)="toggleOldPasswordVisibility()">
                                        {{ showOldPassword ? 'Hide' : 'Show' }}
                                    </button>
                                </div>
                            </div>
                            <div class="mb-4">
                                <label for="newPassword" class="form-label">New Password</label>
                                <div class="input-group">
                                    <input type="{{ showNewPassword ? 'text' : 'password' }}" id="newPassword"
                                        name="newPassword" class="form-control" [(ngModel)]="newPassword" required />
                                    <button type="button" class="btn btn-outline-secondary"
                                        (click)="toggleNewPasswordVisibility()">
                                        {{ showNewPassword ? 'Hide' : 'Show' }}
                                    </button>
                                </div>
                            </div>
                            <div class="text-center">
                                <button type="submit" class="btn btn-primary"
                                    [disabled]="!changePasswordForm.form.valid">
                                    Change Password
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>