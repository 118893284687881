import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class MyInterceptor implements HttpInterceptor {

  constructor(private toastr: ToastrService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // console.log('Intercepted HTTP call', req.url);
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        // Show a toast message
        this.toastr.error('An error occurred. Please try again later.', 'Error');
        console.error('HTTP Error: ', error);
        return throwError(error);
      })
    );
  }
}
