import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AuthServiceService } from '../../auth-service.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AppComponent } from '../../app.component';
import { LoginResponse } from '../../shared/_models/user';

@Component({
  selector: 'app-select-campaign',
  templateUrl: './select-campaign.component.html',
  styleUrls: ['./select-campaign.component.css']
})
export class SelectCampaignComponent implements OnInit {

  public responseObject: any = {};
  public requestObject: any = {};
  public isResponseReceived: boolean;
  public hasError: boolean;
  public orgaisationId: string = localStorage.getItem('org');

  constructor(
    private authService: AuthServiceService,
    private http: HttpClient,
    private router: Router,
    private spinner: NgxSpinnerService,
    private deviceService: DeviceDetectorService,
    private appComponent: AppComponent
  ) {
  }

  ngOnInit(): void {
    this.getCampaigns();
    this.appComponent.isLoggedIn = true;
    // Retrieve and apply stored primary color
    const storedPrimaryColor = localStorage.getItem('primaryColor');
    if (storedPrimaryColor) {
      console.log('Applying stored primary color:', storedPrimaryColor);
      document.documentElement.style.setProperty('--primary-color', storedPrimaryColor);
    } else {
      console.log('No stored primary color found');
    }
  }


  getCampaigns() {
    this.spinner.show();
    const headers = new HttpHeaders()
      .set('Authorization', `Bearer ${this.authService.getToken()}`);
    this.http.get(environment.url + 'campaigns/', { headers }).subscribe(
      (val) => {
        this.spinner.hide();
        this.responseObject = val;
        this.isResponseReceived = true;
      },
      (response) => {
        this.spinner.hide();
        this.hasError = true;
      }
    );
  }

  /**
   * Open the templates page
   * @param campaign
   */
  openTemplates(campaign: any) {
    localStorage.setItem('campaignID', campaign.id);
    this.router.navigate(['/select-template']);
  }
}
