import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment'; // Add this line
import { AuthServiceService } from '../../auth-service.service'; // Add this line

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  user: any = {};
  userid = localStorage.getItem('user_id');

  constructor(private http: HttpClient, private authService: AuthServiceService) { } // Add authService parameter

  ngOnInit(): void {
    this.getProfile();
    // Retrieve and apply stored primary color
    const storedPrimaryColor = localStorage.getItem('primaryColor');
    if (storedPrimaryColor) {
      console.log('Applying stored primary color:', storedPrimaryColor);
      document.documentElement.style.setProperty('--primary-color', storedPrimaryColor);
    } else {
      console.log('No stored primary color found');
    }
  }

  getProfile(): void {
    const accessToken = localStorage.getItem('accessToken');

    if (!accessToken) {
      console.error('No access token found in localStorage');
      return;
    }

    const headers = new HttpHeaders()
      .set('Authorization', `Bearer ${this.authService.getToken()}`);

    this.http.get(environment.url + 'users/' + this.userid, { headers }).subscribe(
      (data) => {
        this.user = data;
      },
      error => {
        console.error('Error fetching profile data:', error);
      }
    );
  }
}
