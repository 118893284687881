<div class="container p-2">
  <div class="red-box">
    <app-header title="Generate Poster"></app-header>

    <div class="row">
      <div class="col-12 col-md-4 offset-md-4 text-center mt-5 mb-5">
        <div class="mt-4">
          <img src="{{ templateData.sourceImage }}" class="img-fluid" />
        </div>

        <div class="row mt-3">
          <div class="col-6 offset-3">
            <button class="btn btn-primary" (click)="openPreviewModal()">Preview</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Preview Modal -->
<div class="modal fade" id="previewModal" tabindex="-1" aria-labelledby="previewModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="previewModalLabel">Preview</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body text-center">
        <img [src]="previewImage" class="img-fluid" *ngIf="previewImage" />
      </div>
      <div class="modal-footer">
        <div class="container">
          <div class="row">
            <div class="col-6 d-flex justify-content-center">
              <button type="button" class="btn btn-primary" (click)="download()">Download</button>
            </div>
            <div class="col-6 d-flex justify-content-center">
              <button type="button" class="btn btn-primary" (click)="share()">Share</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- Poster -->
<div class="photo-preview poster-container d-none" #dynamicContainer id="dynamicContainer"
  [ngStyle]="{ 'background-image': 'url(' + templateData.sourceImage + ')' }"></div>