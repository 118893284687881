<div class="container p-2">
  <div class="red-box">
    <div class="row">
      <div class="col-12 p-2 text-center mt-5 mb-5">
        <!-- Default Logo -->
        <img *ngIf="!brandingDataReceived" src="../../../assets/uns/logo.png" class="img-fluid logo" />

        <!-- Top Banner -->
        <img *ngIf="brandingDataReceived" [src]="topBannerUrl" id="top-banner" class="banner img-fluid" />
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center">
        <img *ngIf="logoUrl" [src]="logoUrl" class="img-fluid" alt="">
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-5 p-md-0 text-center">
        <form (ngSubmit)="login()" #form="ngForm" class="mt-2" autocomplete="off">
          <!-- Email or Employee ID -->
          <mat-form-field appearance="outline" class="w-100 mb-3">
            <mat-label>Email or Employee ID</mat-label>
            <mat-icon matPrefix class="me-3">person</mat-icon>
            <input matInput type="text" name="identifier" placeholder="Enter Email or Employee ID" required
              [(ngModel)]="requestObject.identifier" />
          </mat-form-field>

          <!-- Password -->
          <mat-form-field appearance="outline" class="w-100 mb-3">
            <mat-label>Password</mat-label>
            <mat-icon matPrefix class="me-3">lock</mat-icon>
            <input matInput type="password" name="password" placeholder="Password" required
              [(ngModel)]="requestObject.password" />
          </mat-form-field>

          <!-- Login Button -->
          <div class="form-group text-center mt-4">
            <button class="btn btn-primary w-50" [disabled]="!form.valid" type="submit">
              Login
            </button>
          </div>

          <!-- Error Message -->
          <div class="alert alert-warning mt-4" *ngIf="hasError">
            Invalid Email/Employee ID or Password.
          </div>
        </form>
      </div>
    </div>
    <div class="row">
      <div class="col-12 p-0">
        <img id="bottom-banner" [src]="bottomBannerUrl" class="bottom-banner-margin img-fluid" />
      </div>
    </div>
  </div>
</div>