// shared.module.ts
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms'; // Required for ngModel
import { HeaderComponent } from './header/header.component';
import { OffcanvasComponent } from './offcanvas/offcanvas.component';
import { PaginatorComponent } from './paginator/paginator.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { RouterModule } from '@angular/router';
import { MatPaginatorModule } from '@angular/material/paginator';
import { RemoveUnderscorePipe } from './custom-pipes/remove-underscore.pipe';

@NgModule({
  declarations: [
    HeaderComponent,
    OffcanvasComponent,
    PaginatorComponent,
    RemoveUnderscorePipe
  ],
  imports: [
    CommonModule,
    FormsModule, // Ensure FormsModule is imported here
    ImageCropperModule,
    RouterModule,
    MatPaginatorModule
  ],
  exports: [
    HeaderComponent,
    OffcanvasComponent,
    PaginatorComponent,
    RemoveUnderscorePipe
  ]
})
export class SharedModule { }
