import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EntityDataComponent } from './entity-data/entity-data.component';
import { EntityListingComponent } from './entity-listing/entity-listing.component';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { SharedModule } from '../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { ImageCropperModule } from 'ngx-image-cropper';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: 'entity/:entityID', component: EntityListingComponent },
  { path: 'entity/:entityID/create', component: EntityDataComponent },
  { path: 'entity/:entityID/edit/:id', component: EntityDataComponent }
];
@NgModule({
  declarations: [
    EntityDataComponent,
    EntityListingComponent,
    DynamicFormComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ImageCropperModule,
    Ng2SearchPipeModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    EntityDataComponent,
    EntityListingComponent,
    DynamicFormComponent
  ]
})
export class EntityModule { }
