import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { DomSanitizer } from '@angular/platform-browser';
import html2canvas from 'html2canvas';
declare var $: any; // Add this to use jQuery functions like modal

@Component({
  selector: 'app-generate-poster',
  templateUrl: './generate-poster.component.html',
  styleUrls: ['./generate-poster.component.css']
})
export class GeneratePosterComponent implements OnInit, AfterViewInit {
  @ViewChild('dynamicContainer', { static: false }) dynamicContainer!: ElementRef;

  public entityID = '';
  public templateID = '';
  public templateData: any = {};
  public entityData: any = {};

  public responseObject: any = {};
  public requestObject: any = {};
  public isResponseReceived: boolean;
  public hasError: boolean;
  public posterURL;
  public hasCustomMessage = false;
  public customMessage;
  public oranizationID = localStorage.getItem('org');
  public responseObject2: any = {};
  public posterID = localStorage.getItem('poster_id');
  public doctorImage = localStorage.getItem('doctor_image');
  public doctorName = localStorage.getItem('doctor_name');
  public previewImage: any;
  public posterBlob: any;
  public url: string;
  public doctorImagePostion: any = {};
  public doctorNamePostion: any = {};
  public campaignID = localStorage.getItem('campaignID');
  public headers = new HttpHeaders()
    .set('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));

  constructor(
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private sanitizer: DomSanitizer,
    private renderer: Renderer2,
    private el: ElementRef
  ) { }

  ngOnInit(): void {
    this.posterURL = localStorage.getItem('poster_url');
    if (localStorage.getItem('has_custom_message')) {
      this.hasCustomMessage = true;
    }

    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.entityID = params.get('entityID');
      this.templateID = params.get('templateID');
    });

    // Retrieve and apply stored primary color
    const storedPrimaryColor = localStorage.getItem('primaryColor');
    if (storedPrimaryColor) {
      console.log('Applying stored primary color:', storedPrimaryColor);
      document.documentElement.style.setProperty('--primary-color', storedPrimaryColor);
    } else {
      console.log('No stored primary color found');
    }
  }

  ngAfterViewInit(): void {
    Promise.all([this.getTemplate(), this.getEntityData()]).then((values) => {
      this.templateData = values[0];
      this.entityData = values[1];

      console.log('Template Data:', this.templateData);
      console.log('Entity Data:', this.entityData);

      this.adjustPreviewDimensions();
      this.generatePoster();
    });
  }

  private adjustPreviewDimensions() {
    const img = new Image();
    img.src = this.templateData.sourceImage;

    img.onload = () => {
      const container = this.dynamicContainer.nativeElement as HTMLElement;
      container.style.width = `${img.width}px`;
      container.style.height = `${img.height}px`;
    };
  }

  openPreviewModal() {
    this.generatePreview().then(() => {
      $('#previewModal').modal('show');
    });
  }

  private generatePreview(): Promise<void> {
    return new Promise((resolve) => {
      this.spinner.show();
      document.querySelector('.poster-container')?.classList.remove('d-none');

      html2canvas(this.dynamicContainer.nativeElement, {
        allowTaint: false,
        useCORS: true
      }).then((canvas) => {
        canvas.toBlob((blob: any) => {
          let link = document.createElement('a');
          link.download = this.doctorName + '.png';
          link.href = URL.createObjectURL(blob);
          this.previewImage = this.sanitizer.bypassSecurityTrustUrl(link.href);
          this.posterBlob = blob;
          document.querySelector('.poster-container')?.classList.add('d-none');
          this.spinner.hide();
          resolve();
        }, 'image/png');
      });
    });
  }

  download() {
    if (this.posterBlob) {
      let link = document.createElement('a');
      link.download = this.doctorName + '.png';
      link.href = URL.createObjectURL(this.posterBlob);
      link.click();
    }
  }

  share() {
    if ((window.navigator as any).share) {
      (window.navigator as any).share({
        title: '#World Hepatitis Day 2024',
        text: 'Fight Against Liver Disorder',
        url: this.url
      }).then(() => {
        console.log('Thanks for sharing!');
      })
        .catch(console.error);
    } else {
      window.open(`https://wa.me/?text=${this.url}`, '_blank');
    }
  }

  getTemplate(): Promise<any> {
    this.spinner.show();
    return new Promise((resolve, reject) => {
      this.http.get(environment.url + 'template/' + this.templateID, { headers: this.headers }).subscribe(
        (val) => {
          this.spinner.hide();
          resolve(val);
        },
        (error) => {
          this.spinner.hide();
          this.hasError = true;
          console.error('Error fetching template data:', error);
          reject(error);
        }
      );
    });
  }

  getEntityData(): Promise<any> {
    this.spinner.show();
    return new Promise((resolve, reject) => {
      this.http.get(environment.url + 'entity-data/' + this.entityID, { headers: this.headers }).subscribe(
        (val) => {
          this.spinner.hide();
          resolve(val);
        },
        (response) => {
          this.spinner.hide();
          this.hasError = true;
          reject(response);
        }
      );
    });
  }

  generatePoster() {
    this.templateData.entityConfiguration.fields.forEach((field) => {
      if (field.type === 'text' || field.type === 'tel' || field.type === 'date' || field.type === 'time') {
        field.value = this.entityData.data[field.identifier];
        if (!field.value) {
          console.warn(`Value for ${field.type} field ${field.identifier} is missing.`);
          return; // Skip this field if value is missing
        }

        try {
          field.position = JSON.parse(field.position);
        } catch (e) {
          // console.error(`Error parsing position for ${field.type} field ${field.identifier}:`, e);
          return;
        }

        const div = this.renderer.createElement('div');
        const text = this.renderer.createText(field.value);

        this.renderer.setStyle(div, 'position', 'absolute');
        this.renderer.setStyle(div, 'left', field.position.x + 'px');
        this.renderer.setStyle(div, 'top', field.position.y + 'px');
        this.renderer.setStyle(div, 'font-size', field.position.size + 'px');
        this.renderer.setStyle(div, 'font-weight', field.position.weight);
        this.renderer.setStyle(div, 'color', field.position.color);

        this.renderer.appendChild(div, text);
        this.renderer.appendChild(this.dynamicContainer.nativeElement, div);

      } else if (field.type === 'image') {
        field.value = this.entityData.data[field.identifier];
        if (!field.value) {
          console.warn(`Value for image field ${field.identifier} is missing.`);
          return; // Skip this field if value is missing
        }

        try {
          field.position = JSON.parse(field.position);
        } catch (e) {
          // console.error(`Error parsing position for image field ${field.identifier}:`, e);
          return;
        }

        const img = this.renderer.createElement('img');

        this.renderer.setStyle(img, 'position', 'absolute');
        this.renderer.setStyle(img, 'left', field.position.x + 'px');
        this.renderer.setStyle(img, 'top', field.position.y + 'px');
        this.renderer.setStyle(img, 'width', field.position.width + 'px');
        this.renderer.setStyle(img, 'height', field.position.height + 'px');
        this.renderer.setStyle(img, 'border-radius', field.position.radius + 'px');
        this.renderer.setAttribute(img, 'src', field.value);

        this.renderer.appendChild(this.dynamicContainer.nativeElement, img);
      }
    });
  }
}
