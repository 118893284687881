// src/app/pages/pages.module.ts
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeneratePosterComponent } from './generate-poster/generate-poster.component';
import { MyEntriesComponent } from './my-enteries/my-entries.component';
import { SelectCampaignComponent } from './select-campaign/select-campaign.component';
import { SelectTemplateComponent } from './select-template/select-template.component';
import { FormsModule } from '@angular/forms'; // Import if using ngModel
import { RouterModule, Routes } from '@angular/router'; // Import if using routerLink
import { SharedModule } from '../shared/shared.module';
import { MatListModule } from '@angular/material/list';


// Define routes for pages module
const routes: Routes = [
  { path: 'select-campaign', component: SelectCampaignComponent },
  { path: 'select-template', component: SelectTemplateComponent },
  { path: 'generate/:templateID/:entityID', component: GeneratePosterComponent },
  { path: 'my-entries', component: MyEntriesComponent }
];
@NgModule({
  declarations: [
    GeneratePosterComponent,
    MyEntriesComponent,
    SelectCampaignComponent,
    SelectTemplateComponent
  ],
  imports: [
    CommonModule,
    FormsModule, // Import if using ngModel
    RouterModule.forChild(routes), // Import if using routerLink
    SharedModule,
    MatListModule,
  ],
  exports: [
    GeneratePosterComponent,
    MyEntriesComponent,
    SelectCampaignComponent,
    SelectTemplateComponent
  ]
})
export class PagesModule { }
